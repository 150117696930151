@import '../themes/default';
@import './Menu';

$menu-item-size: $bar-size;

.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $menu-item-size;
  height: $menu-item-size;
  background-color: $menu-bg;
  color: $menu-fg;

  > svg {
    position: relative;
    left: 2px;
    height: 30%;
  }

  &.active {
    background-color: $menu-active-bg;
  }

  &:hover {
    color: $menu-hover-fg;
  }
}
