@import '../themes/default';

.card.configuration-step-card {
  margin: 2em 0;

  .configuration-step-card-help-button {
    position: relative;
    top: -0.5em;
    margin: 1em !important;
  }
}
