@import '../themes/default';

$button-bg: $brand-dark-grey;
$button-fg: $brand-light-grey;
$button-hover-bg: lighten($brand-dark-grey, 10%);
$button-hover-fg: $brand-white;
$button-active-border: $brand-white;

$button-info-bg: $intent-info;
$button-info-fg: $brand-black;

$button-success-bg: $intent-success;
$button-success-fg: $brand-black;

$button-warning-bg: $intent-warning;
$button-warning-fg: $brand-black;

$button-danger-bg: $intent-danger;
$button-danger-fg: $brand-black;

.button {
  box-sizing: border-box;
  outline: 0;
  border: $input-border-width solid transparent;
  border-radius: $border-radius;
  background-color: transparent;
  color: transparent;
  font-family: $font-stack;
  font-size: $font-size;
  padding: 0.5em 1em;
  margin: 1em 0;
  transition: all $transition-time ease-in-out;
  white-space: nowrap;
  cursor: pointer;

  .button-icon {
    margin-right: 1em;
  }

  .extra {
    margin-left: 1em;
  }

  &.button-icon-only .button-icon {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  &.button-primary {
    background-color: $button-bg;
    border-color: $button-bg;
    color: $button-fg;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      outline: 0;
      background-color: $button-hover-bg;
      border-color: $button-hover-bg;
      color: $button-hover-fg;
    }

    &:active:not([disabled]) {
      border-color: $button-active-border;
    }

    &.button-info {
      background-color: $button-info-bg;
      border-color: $button-info-bg;
      color: $button-info-fg;

      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        background-color: $button-info-bg;
        border-color: $button-active-border;
      }
    }

    &.button-success {
      background-color: $button-success-bg;
      border-color: $button-success-bg;
      color: $button-success-fg;

      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        background-color: $button-success-bg;
        border-color: $button-active-border;
      }
    }

    &.button-warning {
      background-color: $button-warning-bg;
      border-color: $button-warning-bg;
      color: $button-warning-fg;

      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        background-color: $button-warning-bg;
        border-color: $button-active-border;
      }
    }

    &.button-danger {
      background-color: $button-danger-bg;
      border-color: $button-danger-bg;
      color: $button-danger-fg;

      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        background-color: $button-danger-bg;
        border-color: $button-active-border;
      }
    }
  }

  &.button-secondary {
    color: $button-fg;

    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
      border-color: $button-hover-bg;
      color: $button-hover-fg;
    }

    &.button-info {
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        border-color: $button-info-bg;
      }
    }

    &.button-success {
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        border-color: $button-success-bg;
      }
    }

    &.button-warning {
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        border-color: $button-warning-bg;
      }
    }

    &.button-danger {
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        border-color: $button-danger-bg;
      }
    }
  }

  &.button-minimal {
    border: 0 !important;
    background-color: transparent !important;
    color: $button-fg !important;
    padding: 0 !important;
    margin: 0 1em !important;

    &:hover,
    &:focus,
    &:active {
      border: 0 !important;
      background-color: transparent !important;
      color: $button-hover-fg !important;
    }

    &:hover:disabled,
    &:focus:disabled {
      color: $button-fg !important;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  .button.button-minimal {
    margin: 0 !important;

    .button-icon {
      display: inline-block;
      width: 1.5em;
    }

    &:first-child {
      margin-right: 1em !important;
    }

    &:not(:first-child) {
      margin-left: 1em !important;
    }
  }
}
