@import '../themes/default';

$menu-width: $bar-size;
$menu-bg: darken($brand-black, 2%);
$menu-fg: $brand-light-grey;
$menu-hover-bg: $brand-dark-grey;
$menu-active-bg: darken($brand-black, 6%);
$menu-hover-fg: $brand-white;

.menu {
  z-index: $layer-menu;
  user-select: none;
  grid-area: menu;
  background-color: $menu-bg;
  font-size: small;
  font-weight: bold;
  width: $menu-width;
  transition: width $transition-time ease-in-out,
    min-width $transition-time ease-in-out;
}

.react-tooltip.menu-tooltip {
  z-index: $layer-modal;
  background-color: $tooltip-bg;
  color: $tooltip-fg;
  transition:
    opacity $transition-time ease-in-out,
    top $transition-time ease-in-out;
  border-radius: $border-radius;

  &.react-tooltip__show {
    opacity: 1;
  }
}
