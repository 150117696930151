@import '../themes/default';

$value-min-width: 200px;
$value-description-fg: fade-out($fg, 0.5);

.value {
  td {
    padding: 0.5em 1em;
    margin: 0.5em;
    vertical-align: top;
    line-height: 2em;

    > * {
      line-height: 1em;
    }

    > svg {
      margin-right: 1em;
    }

    .alert {
      min-height: 1.1em;
      margin-bottom: 1em;
    }

    .extra {
      font-weight: normal;
      font-style: italic;
      font-size: 0.9em;
      color: fade-out($fg, 0.3);
    }
  }

  .value-label {
    font-style: italic;
    min-width: $value-min-width;
    white-space: nowrap;

    .value-label-description {
      display: block;
      margin-top: 0.5em;
      color: $value-description-fg;
    }

    label {
      display: inline-block;
      margin-top: 1.8em;
    }
  }

  .value-data {
    font-weight: bold;

    &.value-data-numeric {
      text-align: right;
    }

    .value-data-description {
      display: block;
      color: $value-description-fg;
      font-weight: normal;
      font-style: italic;
    }
  }

  // Intents
  &.value-info {
    color: $intent-info;
  }

  &.value-success {
    color: $intent-success;
  }

  &.value-warning {
    color: $intent-warning;
  }

  &.value-danger {
    color: $intent-danger;
  }
}

.value-list-inline .value .value-label {
  min-width: 0;
}

.value-list-full-width .value .value-data {
  width: 100%;
}

.task-bar .value td {
  line-height: 2em;
  vertical-align: middle;
}
