@import '../themes/default';

$badge-size: 16px;
$badge-offset: 4px;
$badge-fg: $brand-white;

.badge {
  position: absolute !important;
  margin: 0;
  padding: 0;
  top: -$badge-offset;
  right: -$badge-offset;
  min-width: $badge-size;
  max-width: 64px;
  height: $badge-size;
  border-radius: $border-radius;
  text-align: center;
  font-size: $badge-size - 4px;
  font-weight: bold;
  line-height: $badge-size;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $badge-fg;
  background-color: transparent;
  transform: scale(1);
  opacity: 1;
  transform-origin: center;
  transition: background-color $transition-time ease-in-out,
    transform $transition-time ease-in-out,
    opacity $transition-time ease-in-out;

  svg {
    margin: 0;
  }

  .badge-text {
    font-size: $badge-size - 5px;
    margin: 0 4px;
  }

  // Pulse animation
  &.badge-pulse {
    animation: badge-pulse 1.5s ease-in-out infinite both;
  }

  // Shake animation
  &.badge-shake {
    animation: badge-shake 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }

  // Intents
  &.badge-info {
    background-color: $intent-info;
  }

  &.badge-success {
    background-color: $intent-success;
  }

  &.badge-warning {
    background-color: $intent-warning;
  }

  &.badge-danger {
    background-color: $intent-danger;
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
    transform: scale(0);
  }

  &.exited {
    display: none;
  }
}

h1, h2, h3, h4 {
  .badge {
    right: calc($badge-offset - 2.5em);
  }
}

@keyframes badge-pulse {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(1.1);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(1.2);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes badge-shake {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(-2px);
  }
}
