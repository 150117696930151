@import '../themes/default';

$switch-bg: $input-bg;
$switch-fg: $input-fg;
$switch-border: $input-border;
$switch-focus-border: $input-focus-border;
$switch-height: 1.4em;
$switch-width: $switch-height * 1.8;
$switch-padding: 0.5em;
$switch-inner-size: 1em;
$switch-inner-bg: fade-out($brand-light-grey, 0.5);

.switch {
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 1em;
  display: grid;
  grid-template-columns: $switch-width auto;
  gap: 1em;
  padding: $switch-padding 0;
  margin: 1em 0;
  color: $input-fg;

  &.switch-no-label {
    width: calc($switch-width + 1em);
  }

  &.switch-disabled {
    opacity: 0.4;
  }

  .switch-slider {
    display: block;
    position: absolute;
    top: calc($switch-padding + $input-border-width);
    left: $input-border-width;
    width: $switch-inner-size;
    height: $switch-inner-size;
    border-radius: calc($switch-inner-size / 2);
    background-color: $switch-inner-bg;
    transition: all $transition-time ease-in-out;

    svg {
      position: absolute;
      top: 20%;
      left: 20%;
      width: 60%;
      height: 60%;
      pointer-events: none;
      color: $switch-bg;
    }
  }

  input[type="checkbox"] {
    display: inline-block;
    appearance: none;
    margin: 0;
    background-color: $switch-bg;
    width: $switch-width;
    height: $switch-height;
    min-width: auto;
    border-radius: calc($switch-height / 2);
    border: $input-border-width solid $switch-border;
    display: grid;
    place-content: center;
    transition: all $transition-time ease-in-out;

    &:checked + .switch-slider {
      left: calc($switch-width - $switch-inner-size - $input-border-width);
      background-color: currentColor;
    }

    &:focus {
      border-color: $switch-focus-border;
    }

    &:disabled {
      opacity: 1;
    }
  }

  &.switch-readonly {
    input[type="checkbox"] {
      background-color: transparent;

      &:focus {
        border-color: $switch-border;
      }
    }
  }
}
