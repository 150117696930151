@import '../themes/default';

.configuration-card {
  padding: 0 2em 1em 2em;

  .task-bar {
    padding: 0 !important;
    margin-bottom: 0;

    .switch {
      margin-top: 0;
    }
  }
}
