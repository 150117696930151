@import '../themes/default';

.events-list {
  gap: 0;
}

.event-card {
  padding: 0 2em 1em 2em;

  .task-bar {
    padding: 0 !important;
    margin-bottom: 0;

    .tag {
      position: relative;
      top: 0.5em;
    }
  }

  .value-list.event-card-type {
    margin-right: 0;
  }
}
