@import '../themes/default';

.collapse {
  position: relative;
  z-index: $layer-content;
  margin: 1em 0 2em 0;
  padding: 1em 2em;
  box-sizing: border-box;
  border-radius: $border-radius;
  transform: scale(1, 1);
  overflow: visible;
  opacity: 1;
  transform-origin: center top;
  transition: transform $transition-time ease-in-out,
    opacity $transition-time ease-in-out,
    height $transition-time ease-in-out,
    margin $transition-time ease-in-out,
    background-color $transition-time ease-in-out;

  .collapse-close-button {
    position: absolute;
    top: 12px;
    right: 14px;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: $fg;
    opacity: 0.5;
    transition: opacity $transition-time ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  &.collapse-background {
    background-color: darken($bg, 2%);
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
    transform: scale(1, 0);
    overflow: hidden;
    margin: 0;
  }

  &.exited {
    display: none;
  }
}
