@import '../themes/default';

.json-viewer .json-view {
  width: 100%;
  padding: 15px;
  color: $json-punctuation;
  font-family: $code-font-stack;
  font-size: 1em;
  line-height: 1.5em !important;
}

.json-viewer .json-view .json-view--property {
  color: $json-property;
  font-weight: bold;
}

.json-viewer .json-view .json-view--index {
  color: $json-index;
}

.json-viewer .json-view .json-view--number {
  color: $json-number;
}

.json-viewer .json-view .json-view--string {
  color: $json-string;
}

.json-viewer .json-view .json-view--boolean {
  color: $json-boolean;
}

.json-viewer .json-view .json-view--null {
  color: $json-null;
}
