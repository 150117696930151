@import '../themes/default';

$modal-bg: darken($brand-black, 4%);
$modal-fg: $brand-white;

$modal-space: 2em;
$modal-space-top: calc($bar-size + $modal-space);
$modal-space-bottom: $modal-space;
$modal-space-left: calc($bar-size + $modal-space);
$modal-space-right: $modal-space;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $layer-modal;
  overflow: hidden;
  opacity: 1;
  transition: opacity $transition-time ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade-out($bg, 0.3);
  }

  .modal-container {
    position: relative;
    z-index: $layer-modal;
    width: calc(100% - $modal-space-left - $modal-space-right);
    height: calc(100% - $modal-space-top - $modal-space-bottom);
    margin:
      $modal-space-top
      $modal-space-right
      $modal-space-bottom
      $modal-space-left;
    box-sizing: border-box;
    overflow: hidden;
    background-color: $modal-bg;
    border-radius: $border-radius;

    .modal-close-button {
      float: right;
      position: relative;
      top: 1.4em;
      right: 1.4em;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      color: $modal-fg;
      opacity: 0.5;
      transition: opacity $transition-time ease-in-out;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }

  &.modal-small .modal-container {
    width: 40%;
    height: 30%;
    min-width: 400px;
    min-height: 300px;
  }

  .modal-content {
    position: absolute;
    top: calc($modal-space + 1em);
    bottom: 1em;
    left: 1em;
    right: 1em;
    line-height: 1.2em;
    overflow-y: auto;
    padding: 0 2em;
  }

  &.modal-small .modal-container {
    position: relative;
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
  }

  &.exited {
    display: none;
  }
}
